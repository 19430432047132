<template>
  <v-container fluid class="pa-0">
    <div v-if="overlay">
      <h1>CARGANDO DASHBOARD...</h1>
    </div>
    <div v-if="dashboard" class="d-flex">
      <iframe :src="dashboard" style="border: none" title="Dashboard"></iframe>
    </div>
    <div v-if="!overlay && !dashboard">
      <h1>EPA SIN DASHBOARD ASOCIADO</h1>
    </div>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import axios from "axios";
import firebase from "firebase";

export default {
  data() {
    return {
      dashboard: null,
      overlay: false,
    };
  },
  mounted() {
    this.cargarDashboard();
  },
  methods: {
    datosUsuario() {
      return firebase.auth().currentUser.email;
    },
    async cargarDashboard() {
      this.overlay = true;
      let user = this.datosUsuario();
      let correo = {
        email: user,
      };

      console.log("Buscando dashboard..." + JSON.stringify(correo));
      try {
        const result = await axios.post(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/epa",
          correo
        );
        this.dashboard = result.data;

        console.log(result);
      } catch (error) {
        console.log(error);
      }
      this.overlay = false;
    },
  },
};
</script>
